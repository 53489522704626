<template>
  <div>
    <iframe src="https://enggo.fireprobe.net"
            style="width: 100%; height: 320px; overflow: hidden; border: none;"></iframe>
    <div v-if="useIdentifyNetwork">
      <vue-identify-network
          @network-type="handleNetworkIdentified"
          @network-speed="handleNetworkSpeed"
      >
      </vue-identify-network>
      <div v-if="connectionType">{{ $t('connection_type') }}: <b>{{ connectionType }}</b></div>
      <div v-if="connectionSpeed">{{ $t('connection_speed') }}: <b>{{ connectionSpeed }} Mbps</b></div>
      <div v-if="!connectionType && !connectionSpeed">{{ $t('internet_speed_cannot_be_measured') }}</div>
    </div>
  </div>
</template>

<script>
import { VueIdentifyNetwork } from 'vue-identify-network';

export default {
  name: "InternetTest",
  components: {
    VueIdentifyNetwork
  },
  data() {
    return {
      connectionType: null,
      connectionSpeed: null,
      useIdentifyNetwork: false
    }
  },
  methods: {
    handleNetworkSpeed(e) {
      this.connectionSpeed = e !== 'Unknown' ? e : null
    },
    handleNetworkIdentified(e) {
      this.connectionType = e !== 'Unknown' ? e : null
    },
  }
}
</script>

<style scoped>

</style>
