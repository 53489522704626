<template>
  <div>
    <div v-if="loaded">
      <label>{{ $t('speaker') }}</label>
      <b-row>
        <b-col sm="9">
          <SelectGroup :options="speakers"
                       :translate="false"
                       @input="changeSpeaker"
                       class="pr-sm-2"
                       :hasNullOption="false"
                       v-model="speaker">
          </SelectGroup>
        </b-col>
        <b-col sm="3">
          <b-button class="btn-themed btn-white btn-secondary w-100" @click="testAudio">{{ $t('check') }}</b-button>
        </b-col>
      </b-row>
      <label>{{ $t('microphone') }}</label>
      <b-row>
        <b-col sm="9">
          <SelectGroup :options="microphones"
                       :translate="false"
                       @input="changeMicrophone"
                       :hasNullOption="false"
                       class="pr-sm-2"
                       v-model="microphone">
          </SelectGroup>
        </b-col>
        <b-col sm="3">
          <b-button class="btn-themed btn-white btn-secondary w-100" @click="record">
            {{ isRecording ? $t('stop_and_play') : $t('recording') }}
          </b-button>
        </b-col>
      </b-row>
      
      <div class="position-relative volumebar-wrap mb-4">
        <div class="volumebar-back"></div>
        <div :style="`width: ${volume}%`" class="volumebar"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectGroup from "../general/form/SelectGroup"

export default {
  name: "AudioTest",
  components: {
    SelectGroup
  },
  mounted() {
    navigator.mediaDevices.getUserMedia({
      video: true, 
      audio: true
    }).then(() => {
      this.getDevices()  
    }).catch(() => {
      this.getDevices()
    })
  },
  data() {
    return {
      defaultMicrophone: null,
      microphone: null,
      defaultSpeaker: null,
      speaker: null,
      microphones: [],
      speakers: [],
      scriptProcessor: null,
      mediaRecorder: null,
      audioContext: null,
      analyser: null,
      audioChunks: [],
      isRecording: false,
      volume: 0,
      loaded: false
    }
  },
  methods: {
    getDevices() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log("enumerateDevices() not supported.");
        return;
      }
      let _this = this
      navigator.mediaDevices.enumerateDevices()
          .then(function(devices) {
            devices.forEach(function(device) {
              if(device.kind == 'audioinput' && device.deviceId != 'default') {
                _this.microphones.push({id: device.deviceId, title: device.label})
              }
              if(device.kind == 'audioinput' && device.deviceId == 'default') {
                _this.defaultMicrophone =  device.label.replace('Default - ', '').replace('По умолчанию - ', '')
              }
              if(device.kind == 'audiooutput' && device.deviceId != 'default') {
                _this.speakers.push({id: device.deviceId, title: device.label})
              }
              if(device.kind == 'audiooutput' && device.deviceId == 'default') {
                _this.defaultSpeaker =  device.label.replace('Default - ', '').replace('По умолчанию - ', '')
              }
            });
            if(!_this.defaultMicrophone && _this.microphones.length) {
              _this.defaultMicrophone = _this.microphones[0].title
            }
            if(!_this.defaultSpeaker && _this.speakers.length) {
              _this.defaultSpeaker = _this.speakers[0].title
            }
            _this.loaded = true
            _this.setDefaults()
          })
          .catch(function(err) {
            console.log(err.name + ": " + err.message);
          });
    },
    changeSpeaker() {
    },
    changeMicrophone() {
      // console.log('mic')
      this.launchAudioMeasurer()
    },
    async testAudio() {
      // var audio = new Audio('/files/sound-test.wav')
      const audio = document.createElement('audio');
      audio.src = '/files/sound-test.wav'
      try {
        audio.setSinkId(this.speaker);
      } catch (e) {
        console.log(e)
      }
      audio.play()

    },
    record() {
      if(this.isRecording) {
        this.isRecording = false
        this.mediaRecorder.stop()
        this.playRecorded()
        return
      }
      this.audioChunks = []
      navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: this.microphone } },
      }).then(stream => {
        this.isRecording = true
        this.mediaRecorder = new MediaRecorder(stream)
        this.mediaRecorder.start(50)
        this.mediaRecorder.addEventListener("dataavailable", event => {
          this.audioChunks.push(event.data)
        })
      });
    },
    playRecorded() {
      console.log(this.audioChunks)
      if(!this.audioChunks.length) return
      // this.isPlaying = true
      // console.log(this.audioChunks)
      const audioBlob = new Blob(this.audioChunks)
      const audioUrl = URL.createObjectURL(audioBlob)
      // this.duration = await getBlobDuration(audioBlob)
      // console.log(this.duration)
      if(!this.audio) {
        this.audio = new Audio(audioUrl)
        this.audio.addEventListener("ended", () => {
          this.isPlaying = false
        })
      } else {
        this.audio.src = audioUrl
      }
      this.audio.play()
    },
    async launchAudioMeasurer() {
      let _this = this
      if(_this.scriptProcessor) {
        _this.scriptProcessor.disconnect();
        await _this.audioContext.close();
        _this.scriptProcessor = null
        _this.audioContext = null
        _this.analyser = null
        _this.volume = 0
      }

      let stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: this.microphone } },
      });

      _this.audioContext = new AudioContext();
      _this.analyser = _this.audioContext.createAnalyser();
      const microphone = _this.audioContext.createMediaStreamSource(stream);
      _this.scriptProcessor = _this.audioContext.createScriptProcessor(2048, 1, 1);

      _this.analyser.smoothingTimeConstant = 0.8;
      _this.analyser.fftSize = 1024;

      microphone.connect(_this.analyser);
      _this.analyser.connect(_this.scriptProcessor);
      _this.scriptProcessor.connect(_this.audioContext.destination);
      _this.scriptProcessor.onaudioprocess = function() {
        const array = new Uint8Array(_this.analyser.frequencyBinCount);
        _this.analyser.getByteFrequencyData(array);
        const arraySum = array.reduce((a, value) => a + value, 0);
        _this.volume = arraySum / array.length;
      };

    },
    setDefaults() {
      if(this.defaultMicrophone) {
        let m = this.microphones.find(x => x.title == this.defaultMicrophone)
        if(m) {
          this.microphone = m.id
          this.launchAudioMeasurer()
        }
      }
      if(!this.microphone && this.microphones.length) {
        this.microphone = this.microphones[0].id
      }
      if(this.defaultSpeaker) {
        let s = this.speakers.find(x => x.title == this.defaultSpeaker)
        if(s) this.speaker = s.id
      }
      if(!this.speaker && this.speakers.length) {
        this.speaker = this.speakers[0].id
      }
    }
  }
}
</script>

<style scoped>
.volumebar, .volumebar-back {
  height: 16px;
  border-radius: 5px;
  position: absolute;
  left: 0;
  top: 0;
}
.volumebar-wrap {
  height: 16px;
}
.volumebar {
  background: #25B562;
  z-index: 2;
}
.volumebar-back {
  background: #EDEDED;
  width: 100%;
  z-index: 1;
}
</style>
