<template>
  <b-modal id="testinternetmodal"
           size="lg"
           @show="onShow"
           @hide="onHide"
           modal-class="rounded"
           ref="testinternetmodal">
    <template v-slot:modal-header="{ close }">
      <h2>{{ $t('test_connection') }}</h2>
      <a class="pointer position-relative modal-close" @click="close()">
        <CloseSvg/>
      </a>
    </template>
    <div v-if="loaded">
      <h3>{{ $t('internet') }}</h3>
      <InternetTest/>
      <hr/>
      <h3>{{ $t('video') }}</h3>
      <CameraTest/>
      <hr/>
      <h3>{{ $t('audio') }}</h3>
      <AudioTest/>
      <hr/>
    </div>
    <template v-slot:modal-footer="{ close }">
      <b-button class="btn-themed" @click="close()">
        {{ $t('done') }}
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import CameraTest from "./CameraTest"
import InternetTest from "./InternetTest"
import AudioTest from "./AudioTest"

export default {
  name: "InternetAndDevicesTestModal",
  components: {
    AudioTest,
    InternetTest,
    CameraTest,
    CloseSvg,
  },
  data() {
    return {
      loaded: false
    }

  },
  methods: {
    onShow() {
      this.loaded = true
    },
    onHide() {
      this.loaded = false
    }
  }
}
</script>

<style scoped>

</style>
