<template>
  <div>
    <div class="position-relative d-inline-flex justify-content-center align-items-center">
      <loader v-if="!loaded"/>
      <web-cam ref="webcam"
               @error="camError"
               @cameras="camerasList"
               :width="300"
               :height="220">
      </web-cam>
      <div v-if="error">{{ error }}</div>
    </div>
    <div v-if="loaded" class="mt-2">
      <label class="d-block">{{ $t('camera') }}</label>
      <SelectGroup :options="cameras"
                   v-if="loaded"
                   class="d-inline-block"
                   valuekey="deviceId"
                   title="label"
                   v-model="cameraId"/>
    </div>
  </div>
</template>

<script>
import { WebCam } from "vue-web-cam";
import Loader from "../general/Loader"
import SelectGroup from "../general/form/SelectGroup"

export default {
  name: "CameraTest",
  components: {
    SelectGroup,
    Loader,
    WebCam,
  },
  data() {
    return {
      cameras: [],
      cameraId: null,
      loaded: false,
      error: null
    }
  },
  methods: {
    camError(e) {
      console.log(e)
      this.error = e
    },
    camerasList(e) {
      this.cameras = e
      if(this.cameras.length) {
        this.cameraId = this.cameras[0].deviceId
      }
      this.loaded = true
    },
  },
  watch: {
    cameraId(val) {
      this.$refs.webcam.changeCamera(val)
    }
  }

}
</script>

<style scoped lang="scss">
</style>
