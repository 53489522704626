import { render, staticRenderFns } from "./InternetAndDevicesTestModal.vue?vue&type=template&id=3d84f1d2&scoped=true&"
import script from "./InternetAndDevicesTestModal.vue?vue&type=script&lang=js&"
export * from "./InternetAndDevicesTestModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d84f1d2",
  null
  
)

export default component.exports